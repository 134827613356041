<template>
  <VApp id="inspire">
    <VNavigationDrawer
      v-model="drawer"
      app
      clipped
      color="#FAFAFB"
      expand-on-hover
      floating
    >
      <VList
        class="side-nav"
        dense
        nav
      >
        <VListItem
          v-for="item in items"
          :key="item.text"
          class="red--text"
          :to="'/' + item.ref"
        >
          <VListItemAction
            class="d-flex justify-center my-1"
          >
            <VIcon
              v-if="item.icon"
              v-text="item.icon"
            />
            <img
              v-else
              :alt="item.image"
              :src="require(`@/assets/icons/${item.image}.svg`)"
            >
          </VListItemAction>
          <VListItemContent>
            <VListItemTitle
              class="subtitle-2 nav-title"
            >
              {{ item.text }}
            </VListItemTitle>
          </VListItemContent>
        </VListItem>
      </VList>
    </VNavigationDrawer>

    <VAppBar
      app
      clipped-left
      color="#E43A35"
      dense
    >
      <VAppBarNavIcon @click.stop="drawer = !drawer" />
      <VIcon
        class="mx-4"
        v-text="'fab fa-youtube'"
      />
      <VToolbarTitle class="mr-12 align-center">
        <span class="hermes-title mr-1">
          Chocofood
        </span>
        <span class="hermes-title hermes-title-bold">
          Hermes
        </span>
      </VToolbarTitle>
      <div class="flex-grow-1" />
      <span class="user-title">
        {{ $keycloak.userName }}
      </span>
      <AuthLogoutButton />
    </VAppBar>

    <VContent ref="errorMessage">
      <VSnackbar
        v-model="haveError"
        class="main-snackbar"
        color="white"
        content-class="error"
      >
        <span class="px-4">
          {{ errorMsg }}
        </span>
        <VBtn
          class="close-error-btn mx-1"
          color="red"
          icon
          text
          @click="haveError = false"
        >
          <VIcon v-text="'mdi-close'" />
        </VBtn>
      </VSnackbar>
      <RouterView />
      <div v-if="loading">
        <VProgressCircular
          color="#E43A35"
          indeterminate
          :size="80"
          :width="6"
        />
      </div>
    </VContent>
  </VApp>
</template>

<script>
import { eventHub } from '@/global/eventhub';
import AuthLogoutButton from '../components/AuthLogoutButton.vue';

export default {
  components: {
    AuthLogoutButton,
  },
  data() {
    return {
      drawer: null,
      items: [
        { image: 'home', text: 'Заведения', ref: 'restaurants' },
        { image: 'search', text: 'Категории', ref: 'categories' },
        { image: 'wallet', text: 'Тарифы', ref: 'tariffs' },
        { image: 'map-marker', text: 'Зоны доставки', ref: 'delivery-areas' },
        { image: 'book', text: 'Меню', ref: 'menu' },
        { image: 'stories', text: 'Stories', ref: 'stories' },
        { image: 'gift', text: 'Акции', ref: 'promotions' },
        { image: 'collections', text: 'Коллекции', ref: 'collections' },
        { image: 'image', text: 'Баннеры', ref: 'banners' },
        { image: 'arrow-up', text: 'ТОП-6', ref: 'top_6' },
        { image: 'upsell', text: 'Upsell', ref: 'upsell' },
        { image: 'speedometer', text: 'Производительность', ref: 'image-performance' },
        { image: 'check-outline', text: 'Модерация', ref: 'moderation' },
        { image: 'hands', text: 'Будущие партнеры', ref: 'income-partners' },
        { image: 'pencil-outline', text: 'Контракты', ref: 'contracts' },
        { image: 'book-with-text', text: 'Справочник чипс', ref: 'chips' },
        { icon: 'mdi-tag-multiple', text: 'Метки', ref: 'hubs' },
        { icon: 'mdi-tag-outline', text: 'Метки блюд', ref: 'labels' },
        { icon: 'mdi-content-copy', text: 'Синонимы', ref: 'synonyms' },
        { icon: 'mdi-heart-broken', text: 'Интеграция', ref: 'integration' },
        { image: 'integrations', text: 'Smart Restaurant', ref: 'smart-restaurant' },
        { image: 'notification', text: 'Сервисные баннеры', ref: 'notifications' },
        { icon: 'mdi-shape-polygon-plus', text: 'Покрытие города', ref: 'polygons' },
      ],
      loading: false,
      haveError: false,
      errorMsg: '',
    };
  },
  methods: {
    showSpinner() {
      this.loading = true;
    },
    hideSpinner() {
      this.loading = parseInt(localStorage.getItem('requestsCount'), 10) !== 0;
    },
    showErrorMsg(error) {
      this.haveError = true;
      this.loading = false;
      this.errorMsg = error[0].msg ? error[0].msg : error;
    },
  },
  created() {
    eventHub.$on('before-request', this.showSpinner);
    eventHub.$on('request-error', (errorMessage) => {
      this.showErrorMsg(errorMessage);
    });
    eventHub.$on('after-response', this.hideSpinner);
    eventHub.$on('response-error', (errorMessage) => {
      this.showErrorMsg(errorMessage);
    });
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/mixin.scss';

.title,
.headline,
.exit {
  color: white;
}
.v-progress-circular {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 20px;
  z-index: 2;
}
.v-alert {
  margin-top: 10px;
}
.v-list-item--active {
  color: red;
}
.main-snackbar .v-snack__content {
  font-size: 1.2em;
  color: $hover_red_color;
  position: relative;
  .close-error-btn {
    position: absolute;
    right: 0;
    top: 10%;
  }
}
.hermes-title {
  font-family: 'AvenirNextCyr Demi';
  font-size: 15pt;
  color: white;
}
.hermes-title-bold {
  font-family: 'AvenirNextCyr Black';
  padding-right: 20px;
}
.v-text-field__details {
  display: none;
}
.v-text-field.v-text-field--enclosed {
  padding-top: 3px;
  font-size: 14px;
}
.user-title {
  font-family: 'AvenirNextCyr Demi';
  font-size: 15pt;
  color: white;
}
.nav-title {
  color: #4D4F67;
  line-height: 18px;
  font-weight: 500;
}
.side-nav .v-list-item--active .v-list-item__title {
  color: #E43539;
}
.side-nav .v-list-item--link:before {
  border-radius: 8px !important;
}
.side-nav .v-list-item--active img {
  filter: invert(40%) sepia(98%) saturate(3915%) hue-rotate(336deg) brightness(87%)
  contrast(105%);
}
.side-nav .v-icon {
  color: #4D4F67;
}
</style>
