<template>
  <VBtn
    class="ma-2 exit"
    color="white"
    text
    @click="logout"
  >
    <VIcon color="white" left>
      mdi-logout
    </VIcon>
    Выход
  </VBtn>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$keycloak.logoutFn();
    },
  },
};
</script>
